// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-dydaktyka-index-tsx": () => import("./../../../src/pages/dydaktyka/index.tsx" /* webpackChunkName: "component---src-pages-dydaktyka-index-tsx" */),
  "component---src-pages-dydaktyka-instrukcje-tsx": () => import("./../../../src/pages/dydaktyka/instrukcje.tsx" /* webpackChunkName: "component---src-pages-dydaktyka-instrukcje-tsx" */),
  "component---src-pages-dydaktyka-konsultacje-tsx": () => import("./../../../src/pages/dydaktyka/konsultacje.tsx" /* webpackChunkName: "component---src-pages-dydaktyka-konsultacje-tsx" */),
  "component---src-pages-en-didactics-consultations-tsx": () => import("./../../../src/pages/en/didactics/consultations.tsx" /* webpackChunkName: "component---src-pages-en-didactics-consultations-tsx" */),
  "component---src-pages-en-didactics-index-tsx": () => import("./../../../src/pages/en/didactics/index.tsx" /* webpackChunkName: "component---src-pages-en-didactics-index-tsx" */),
  "component---src-pages-en-didactics-instructions-tsx": () => import("./../../../src/pages/en/didactics/instructions.tsx" /* webpackChunkName: "component---src-pages-en-didactics-instructions-tsx" */),
  "component---src-pages-en-download-tsx": () => import("./../../../src/pages/en/download.tsx" /* webpackChunkName: "component---src-pages-en-download-tsx" */),
  "component---src-pages-en-employees-tsx": () => import("./../../../src/pages/en/employees.tsx" /* webpackChunkName: "component---src-pages-en-employees-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../../../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-offer-tsx": () => import("./../../../src/pages/en/offer.tsx" /* webpackChunkName: "component---src-pages-en-offer-tsx" */),
  "component---src-pages-en-projects-tsx": () => import("./../../../src/pages/en/projects.tsx" /* webpackChunkName: "component---src-pages-en-projects-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-pobieranie-tsx": () => import("./../../../src/pages/pobieranie.tsx" /* webpackChunkName: "component---src-pages-pobieranie-tsx" */),
  "component---src-pages-pracownicy-tsx": () => import("./../../../src/pages/pracownicy.tsx" /* webpackChunkName: "component---src-pages-pracownicy-tsx" */),
  "component---src-pages-projekty-tsx": () => import("./../../../src/pages/projekty.tsx" /* webpackChunkName: "component---src-pages-projekty-tsx" */)
}

